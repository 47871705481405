import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, permission }) => {
  const token = localStorage.getItem("acstkn");
  const { isSuperAdmin, permissions } = useSelector((state) => state.auth);
  if (!token) return <Navigate to="/login" />;
  if (permissions?.length > 0) {
    if (isSuperAdmin || permissions?.includes(permission)) return children;
    return (
      <div className="w-full h-[80vh] flex flex-col items-center justify-center gap-6">
        <h3 className="text-red-400 text-2xl">
          Your account does not have the necessary permissions to view this
          page.
        </h3>
        <h3 className="text-red-400 text-2xl">
          If you require access, please contact the administrator.
        </h3>
      </div>
    );
  } else return null;
};

export default ProtectedRoute;
