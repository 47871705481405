import { lazy } from "react";
import ProtectedRoute from "../components/shared/ProtectedRoute";
import { Route, Routes } from "react-router-dom";

const PromotionList = lazy(() => import("../pages/Promotions/PromotionList"));
const Campaign = lazy(() => import("../pages/Promotions/Campaign"));
const Categories = lazy(() => import("../pages/Promotions/Categories"));
const PromotionControl = lazy(() =>
  import("../pages/Promotions/PromotionControl")
);

export default function PromotionRoutes() {
  return (
    <Routes>
      <Route
        path="list"
        element={
          <ProtectedRoute permission="view_promotion">
            <PromotionList />
          </ProtectedRoute>
        }
      />
      <Route
        path="category"
        element={
          <ProtectedRoute permission="view_promotion">
            <Categories />
          </ProtectedRoute>
        }
      />
      <Route
        path="campaign"
        element={
          <ProtectedRoute permission="view_promotion">
            <Campaign />
          </ProtectedRoute>
        }
      />
      <Route
        path="create"
        element={
          <ProtectedRoute permission="create_promotion">
            <PromotionControl />
          </ProtectedRoute>
        }
      />
      <Route
        path="edit"
        element={
          <ProtectedRoute permission="edit_promotion">
            <PromotionControl />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
