import { Routes, Route, useLocation } from "react-router-dom";
import { Suspense, lazy } from "react";

import Layout from "./components/Layout";
import ProductRelatedRoutes from "./routes/ProductRoutes";
import DIYRoutes from "./routes/DIYRoutes";
import LocationRoutes from "./routes/LocationRoutes";
import ControlRoutes from "./routes/ControlsRoutes";
import PromotionRoutes from "./routes/PromotionRoutes";
import ServiceRoutes from "./routes/ServiceRoutes";
import OrderRoutes from "./routes/OrderRoutes";
import LoadingSpinner from "./components/shared/LoadingSpinner";
import Contacts from "./pages/Contacts";
import Notifications from "./pages/Notifications";
import LogsRoutes from "./routes/LogsRoutes";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Login = lazy(() => import("./pages/Login"));
const NotFound = lazy(() => import("./pages/NotFound"));

export default function RouterConfig() {
  const { pathname } = useLocation();
  return (
    <Layout pathname={pathname}>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/*" element={<ProductRelatedRoutes />} />
          <Route path="/orders/*" element={<OrderRoutes />} />
          <Route path="/diy/*" element={<DIYRoutes />} />
          <Route path="/logs/*" element={<LogsRoutes />} />
          <Route path="/location/*" element={<LocationRoutes />} />
          <Route path="/control/*" element={<ControlRoutes />} />
          <Route path="/promotions/*" element={<PromotionRoutes />} />
          <Route path="/services/*" element={<ServiceRoutes />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Layout>
  );
}
