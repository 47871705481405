import React from "react";

export default function FormModalContainer({
  title,
  children,
  maxWidth = "max-w-2xl",
}) {
  return (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-30">
      <div className={`relative w-full my-6 mx-auto ${maxWidth}`}>
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-start justify-between p-5 border-b rounded-t">
            <h3 className="text-lg font-semibold">{title}</h3>
          </div>
          <div className="relative py-2 px-6 flex-auto">{children}</div>
        </div>
      </div>
    </div>
  );
}
