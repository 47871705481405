import { lazy } from "react";
import ProtectedRoute from "../components/shared/ProtectedRoute";
import { Route, Routes } from "react-router-dom";

const ProductLogs = lazy(() => import("../pages/Logs/ProductLogs"));
const OrderLogs = lazy(() => import("../pages/Logs/OrderLogs"));
const SKULogs = lazy(() => import("../pages/Logs/SkuLogs"));
const StockLogs = lazy(() => import("../pages/Logs/StockLogs"));

export default function LogsRoutes() {
  return (
    <Routes>
      <Route
        path="/products"
        element={
          <ProtectedRoute permission="*">
            <ProductLogs />
          </ProtectedRoute>
        }
      />
      <Route
        path="/orders"
        element={
          <ProtectedRoute permission="*">
            <OrderLogs />
          </ProtectedRoute>
        }
      />
      <Route
        path="/suk"
        element={
          <ProtectedRoute permission="*">
            <SKULogs />
          </ProtectedRoute>
        }
      />
      <Route
        path="/stocks"
        element={
          <ProtectedRoute permission="*">
            <StockLogs />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
