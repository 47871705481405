import moment from "moment";

export function humanize(str, sprate = "_") {
  if (str === undefined) {
    return str;
  }
  var i,
    frags = str.split(sprate);
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
}

export function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function dateFormat(date, format = "") {
  let fText = "MMMM Do YYYY, h:mm:ss a";
  if (format === "full") {
    fText = "MMMM Do YYYY, h:mm a";
  } else if (format === "date") {
    fText = "MMMM Do YYYY";
  } else if (format === "fhour") {
    fText = "h:mm:ss a";
  } else if (format === "hour") {
    fText = "h:mm";
  } else {
    fText = format;
  }
  return moment(date).format(fText);
}

export function getTotalPages(total, per_page) {
  return Math.ceil(total / per_page);
}

export function makeItSelectable(data, custom) {
  if (data?.length > 0) {
    return data.map((item) => {
      let obj = { value: item?.id, label: item?.name };
      if (custom) obj[custom] = item[custom];
      return obj;
    });
  } else return [];
}

export function parseJSON(string) {
  try {
    return JSON.parse(string);
  } catch (error) {
    return "";
  }
}

export function priceFormat(price) {
  return Number(price).toLocaleString("en-US");
}

export function getCurrentDate() {
  return new Date().toISOString().slice(0, 10);
}
