import React from "react";
import { dateFormat } from "../../_helper/helper";

export default function ShippingLabel({ order }) {
  let shipment = {
    name: order?.billing_name,
    address: order?.billing_address,
    phone: order?.billing_phone,
    township: order?.billing_township?.name,
  };
  if (order?.diff_area_status === 1) {
    shipment = {
      name: order?.shipping_name,
      address: order?.shipping_address,
      phone: order?.shipping_phone,
      township: order?.shipping_township?.name,
    };
  }
  return (
    <table className="w-full border-spacing-0">
      <tbody>
        <tr className="border-t-0 border-l-0">
          <td className="border-2 border-[#000] p-4" colSpan="2">
            Order Number : {order?.order_number}
          </td>
          <td className="border-2 border-[#000] p-4">
            {dateFormat(order?.created_at, "full")}
          </td>
        </tr>
        <tr className="border-t-0 border-l-0">
          <td className="border-2 border-[#000] p-4" colSpan="2">
            <div className="flex items-start gap-2">
              <h4 className="font-bold whitespace-nowrap">From : </h4>
              <div className="w-full ">
                <h4 className="font-seminbold">OliveGo Home Improvements</h4>
                <p className="mt-2">
                  အခန်း (၄၊၅၊၆)၊ အဆောင် (၃၆)၊ ကျောက်စိမ်း (၃) လမ်း၊
                  စော်ဘွားကြီးကုန်းဝင်း၊ အင်းစိန်မြို့နယ်၊ ရန်ကုန်မြို့။
                </p>
                <p className="mt-2">09-880566420</p>
              </div>
            </div>
          </td>
          <td className="border-2 border-[#000] p-4" rowSpan="2">
            <img
              alt=""
              className="w-full max-w-[11rem] aspect-square mx-auto"
              src="/image/qr.png"
            />
            <p className="text-center mt-2">https://olivego.com</p>
          </td>
        </tr>
        <tr className="border-t-0 border-l-0">
          <td className="border-2 border-[#000] p-4" colSpan="2">
            <div className="flex items-start gap-2">
              <h4 className="font-bold whitespace-nowrap">To : </h4>
              <div className="w-full ">
                <h4 className="font-seminbold">{shipment.name}</h4>
                <p className="mt-2">
                  {shipment.address} {shipment.township}
                </p>
                <p className="mt-2">{shipment.phone}</p>
              </div>
            </div>
          </td>
        </tr>
        <tr className="border-t-0 border-l-0">
          <td className="border-2 border-[#000] p-4" rowSpan={3}>
            <p className="text-4xl font-bold mb-4">
              {order?.payment_method === "COD" ? "Unpaid" : "Prepaid"}
            </p>
            Payment: {order?.payment_method?.split("_")?.join(" ")}
          </td>
          <td className="border-2 border-[#000] p-4">Total Amount</td>
          <td className="border-2 border-[#000] p-4">
            {order?.summary_price} Ks
          </td>
        </tr>
        <tr className="border-t-0 border-l-0">
          <td className="border-2 border-[#000] p-4">Delivery Charges</td>
          <td className="border-2 border-[#000] p-4">
            {order?.delivery_price} Ks
          </td>
        </tr>
        <tr className="border-t-0 border-l-0">
          <td className="border-2 border-[#000] p-4">Grand Total</td>
          <td className="border-2 border-[#000] p-4">
            {order?.summary_price} Ks
          </td>
        </tr>
      </tbody>
    </table>
  );
}
