import React, { useState } from "react";
import Select from "react-select";
import useLocations from "../../hooks/select-hooks/useLocations";
import useRefetch from "../../hooks/useRefetch";
import { fetchDataFrom, postDataTo } from "../../_helper/fetcher";
import useButtons from "../../hooks/useButtons";
import { makeItSelectable } from "../../_helper/helper";
import { useEffect } from "react";

export default function DeliveryFeeForm({ type, data, close }) {
  const [region, setRegion] = useState({
    value: data?.region?.id || "",
    label: data?.region?.name || "",
  });
  const [city, setCity] = useState({
    value: data?.city?.id || "",
    label: data?.city?.name || "",
  });
  const [township, setTownship] = useState({
    value: data?.township?.id || "",
    label: data?.township?.name || "",
  });

  const [cities, setCities] = useState([]);
  const [townships, setTownships] = useState([]);
  const { refetchList } = useRefetch();
  const { CloseButton, SaveButton } = useButtons();

  const regions = useLocations("regions");

  const getLocations = async (endpoint, set) => {
    const res = await fetchDataFrom(endpoint);
    const locations = makeItSelectable(res);
    set(locations);
  };

  useEffect(() => {
    if (region.value) {
      getLocations("regions/" + region.value, (cityes) => setCities(cityes));
    }
  }, [region.value]);
  useEffect(() => {
    if (city.value) {
      getLocations("cities/" + city.value, (ts) => setTownships(ts));
    }
  }, [city.value]);

  async function controlLocation(e) {
    e.preventDefault();
    const frmData = new FormData();
    if (type === "edit") frmData.append("_method", "PUT");
    frmData.append("region_id", region.value);
    frmData.append("city_id", city.value);
    frmData.append("township_id", township.value);
    frmData.append("price", e.target.price.value);

    const res = await postDataTo(
      type === "edit" ? "delivery-fees/" + data.id : "delivery-fees",
      frmData
    );
    refetchList("fees");
    if (res?.id) close();
  }

  return (
    <form onSubmit={controlLocation}>
      <div className="w-full flex items-center gap-4">
        <div className="w-full">
          <label className="form-label">Select Region</label>
          <Select
            onChange={(e) => setRegion(e)}
            name="location"
            className="react-select"
            options={regions}
            value={region}
          />
        </div>
        <div className="w-full">
          <label className="form-label">Select City</label>
          <Select
            onChange={(e) => setCity(e)}
            name="location"
            className="react-select"
            options={cities}
            value={city}
          />
        </div>
      </div>
      <div className="w-full flex items-center gap-4">
        <div className="w-full">
          <label className="form-label">Select Township</label>
          <Select
            onChange={(e) => setTownship(e)}
            name="location"
            className="react-select"
            options={townships}
            value={township}
          />
        </div>
        <div className="w-full">
          <label className="form-label">Price</label>
          <input
            required
            name="price"
            className="name-input"
            type="number"
            placeholder="Price"
            defaultValue={data?.price || ""}
          />
        </div>
      </div>

      <div className="flex items-center justify-end mt-4">
        <CloseButton onClick={close} />
        <SaveButton />
      </div>
    </form>
  );
}
