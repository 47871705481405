import React, { Fragment } from "react";
import useButtons from "../../hooks/useButtons";
import LoadingListSkeleton from "./LoadingListSkeleton";

export default function TableList({
  isError = false,
  data = [],
  onDetail,
  onEdit,
  onDelete,
  columns = [],
  customButton = null,
  onCustomClick,
  delPer,
  editPer,
  customElements = [],
  customColumns = [],
  isLoading = false,
  loadingRows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
}) {
  const { EditButton, DeleteButton, DetailButton } = useButtons();

  function getValue(item, keys = []) {
    let value = item;
    try {
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] === "joinFunc") {
          value = value?.map((value) => value?.name).join(", ");
          break;
        }
        if (keys[i] === "splitJoinFunc") {
          value = item[keys[i - 1]]?.split("_")?.join(" ");
          break;
        }
        if (keys[i] === "yesItsPrice") {
          value = Number(item[keys[i - 1]]).toLocaleString() + " MMK";
          break;
        }
        value = value[keys[i]];
      }
    } catch (error) {
      value = "";
    }
    return value;
  }

  function checkIsImage(accessor = []) {
    return accessor.includes("thumbnail") || accessor.includes("file");
  }
  return (
    <div className="box-body mb-0 w-full">
      <div className="table-box">
        <div className="table-responsive">
          <div className="table-full mb-0 pt-0.5">
            <div className="table-main">
              <table className="table table-auto">
                <thead>
                  <tr>
                    {columns.map((col) => (
                      <th scope="col" key={col.name}>
                        {col.name}
                      </th>
                    ))}
                    {customColumns.map((col) => (
                      <th scope="col" key={col.name}>
                        {col.name}
                      </th>
                    ))}
                    <th scope="col">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!isError && !isLoading && (
                    <>
                      {data?.length > 0 ? (
                        data.map((item, index) => (
                          <tr key={item.id}>
                            {columns.map((col) => {
                              const colValue = `${getValue(
                                item,
                                col.accessor
                              )}`;
                              return (
                                <Fragment key={col.name}>
                                  {checkIsImage(col.accessor) ? (
                                    <td>
                                      {colValue ? (
                                        <>
                                          {col.name?.includes("banner") ? (
                                            <div className="flex-shrink-0 w-28 aspect-video">
                                              <img
                                                className="w-full h-full object-contain"
                                                src={colValue}
                                                alt=""
                                              />
                                            </div>
                                          ) : (
                                            <div className="flex-shrink-0 h-10 w-10">
                                              <img
                                                className="h-10 w-10 rounded-full"
                                                src={colValue}
                                                alt=""
                                              />
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                  ) : (
                                    <td>
                                      {colValue.startsWith("#") ? (
                                        <div className="flex items-center gap-2">
                                          <div
                                            className="w-12 h-6"
                                            style={{
                                              backgroundColor: colValue,
                                            }}
                                          ></div>
                                          <p>{colValue ? colValue : "-"}</p>
                                        </div>
                                      ) : (
                                        <>
                                          {colValue && colValue !== "null"
                                            ? colValue
                                            : "-"}
                                        </>
                                      )}
                                    </td>
                                  )}
                                </Fragment>
                              );
                            })}
                            {customElements.map((element, index) => (
                              <td key={index}>
                                {React.cloneElement(element, {
                                  item,
                                })}
                              </td>
                            ))}
                            <td className="text-sm gap-1 flex items-center justify-end">
                              {customButton &&
                                React.cloneElement(customButton, {
                                  item,
                                  top: `${index === 0}`,
                                  onClick: () => onCustomClick(item),
                                })}
                              {onDetail && (
                                <DetailButton onClick={() => onDetail(item)} />
                              )}
                              {onEdit && (
                                <EditButton
                                  onClick={() => onEdit(item)}
                                  permission={editPer}
                                />
                              )}
                              {item?.can_delete === true ? (
                                <>
                                  <DeleteButton
                                    onClick={() => onDelete(item)}
                                    permission={delPer}
                                  />
                                </>
                              ) : item?.can_delete === false ? (
                                ""
                              ) : (
                                <>
                                  {" "}
                                  {onDelete && (
                                    <DeleteButton
                                      onClick={() => onDelete(item)}
                                      permission={delPer}
                                    />
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={7}>
                            Empty
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                  {isLoading && (
                    <LoadingListSkeleton
                      rows={loadingRows}
                      columns={[...columns, ...customColumns]}
                    />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
