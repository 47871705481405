import { useSelector } from "react-redux";
import { useMemo } from "react";
import useSidebar from "../data/sidebarMenus";

export default function useSidebarMenus() {
  const { permissions, isSuperAdmin } = useSelector((state) => state.auth);
  const { menus } = useSidebar();
  const sidebarMenus = useMemo(() => {
    if (!permissions.length) return [];
    if (isSuperAdmin) {
      return menus;
    } else {
      let filteredMenus = [];
      menus.forEach((menu) => {
        const filtered = menu[1].filter((men) =>
          permissions?.includes(men.permission)
        );
        if (filtered.length > 0) {
          filteredMenus.push([menu[0], filtered]);
        }
      });
      return filteredMenus;
    }
  }, [permissions, isSuperAdmin, menus]);
  return sidebarMenus;
}
