import React from "react";

const DeletePopUp = ({ close, func, text }) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-md">
          <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white justify-center items-center">
            <h5 className="text-base text-center font-semibold mt-6">
              Are you sure?
            </h5>
            <p className="text-sm text-center my-4 max-w-[40ch]">
              {text
                ? text
                : "Do you really want to delete this? This data cannot be restored."}
            </p>
            <div className="relative pb-6 flex items-center justify-center gap-12">
              <button className="text-red-500" type="button" onClick={close}>
                Close
              </button>
              <button className="btn-md btn-olive" type="submit" onClick={func}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default DeletePopUp;
