import { useNavigate, useSearchParams } from "react-router-dom";

const useQueryString = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const addQueryParams = (queryKey, value) => {
    if (!value) {
      searchParams.delete(queryKey);
    } else {
      searchParams.set(queryKey, value);
    }

    // Update the search parameters and navigate
    setSearchParams(searchParams);
    navigate({ search: searchParams.toString() });
  };

  const removeParam = (queryKey) => {
    searchParams.delete(queryKey);

    // Update the search parameters and navigate
    setSearchParams(searchParams);
    navigate({ search: searchParams.toString() });
  };

  return {
    addQueryParams,
    queryString: "?" + searchParams.toString(),
    removeParam,
  };
};

export default useQueryString;
