import React from "react";

import {
  FaEdit,
  FaEye,
  FaFileExport,
  FaPlusCircle,
  FaTrash,
} from "react-icons/fa";
import { useSelector } from "react-redux";

export default function useButtons() {
  const { permissions, isSuperAdmin } = useSelector((state) => state.auth);
  function AddNewBtn({ onClick, text, permission }) {
    if (isSuperAdmin || permissions.includes(permission)) {
      return (
        <button
          onClick={onClick}
          type="button"
          className="btn-olive btn-sm flex items-center"
        >
          <FaPlusCircle className="mr-2" />
          {text}
        </button>
      );
    }
    return "";
  }

  function EditButton({ onClick, permission }) {
    if (isSuperAdmin || permissions.includes(permission)) {
      return (
        <button
          onClick={onClick}
          type="button"
          className="btn-warning btn-sm mr-1 btn-icon"
        >
          <FaEdit className="icon" />
        </button>
      );
    }
    return "";
  }

  function DeleteButton({ onClick, permission }) {
    if (isSuperAdmin || permissions.includes(permission)) {
      return (
        <button
          onClick={onClick}
          type="button"
          className="btn-danger btn-sm mr-1 btn-icon"
        >
          <FaTrash className="icon" />
        </button>
      );
    }
    return "";
  }
  function DetailButton({ onClick, permission }) {
    if (isSuperAdmin || permissions.includes(permission)) {
      return (
        <button
          onClick={onClick}
          type="button"
          className="btn-success btn-sm mr-1 btn-icon"
        >
          <FaEye className="icon" />
        </button>
      );
    }
    return "";
  }

  function ExportButton({
    onClick,
    permission,
    isLoading = false,
    label = "Export",
  }) {
    if (isSuperAdmin || permissions.includes(permission)) {
      return (
        <button
          onClick={onClick}
          type="button"
          disabled={isLoading}
          className="btn-olive btn-sm flex items-center gap-2 disabled:brightness-50 disabled:cursor-not-allowed"
        >
          <FaFileExport className="icon" />
          {isLoading ? "Exporting..." : label}
        </button>
      );
    }
    return "";
  }

  function SaveButton({
    onClick,
    isLoading = false,
    loadingText = "Saving...",
    text = "Save",
    type = "submit",
    ...props
  }) {
    return (
      <button
        onClick={onClick}
        type={type}
        className="btn-olive btn-md"
        disabled={isLoading}
        {...props}
      >
        {isLoading ? loadingText : text}
      </button>
    );
  }

  function CloseButton({ onClick, text = "Close" }) {
    return (
      <button
        className="btn btn-md bg-transparent text-red-400 shadow-none"
        type="button"
        onClick={onClick}
      >
        {text}
      </button>
    );
  }
  return {
    AddNewBtn,
    EditButton,
    DeleteButton,
    CloseButton,
    SaveButton,
    DetailButton,
    ExportButton,
  };
}
