import React from "react";
import { priceFormat } from "../../_helper/helper";

export default function OrderItems({ total, items = [] }) {
  return (
    <div className="mt-8">
      <h3 className="font-bold text-lg mb-4">Order Summary</h3>
      <div>
        <div className="grid grid-cols-9 font-bold text-sm bg-slate-100 py-2 px-4">
          <h3>Image</h3>
          <h3 className="col-span-4">Product</h3>
          <h3>Quantity</h3>
          <h3>Unit Price</h3>
          <h3>Discount</h3>
          <h3>Price</h3>
        </div>
        {items.map((item) => {
          const {
            sku: { price, variations = [], product },
            product_promo,
            get_y_products,
            summary_price,
            quantity,
          } = item;
          return (
            <div key={item.id}>
              <div className="grid grid-cols-9 p-4 items-center">
                <img
                  src={product?.media[0]?.thumbnail}
                  alt=""
                  className="w-14 aspect-square"
                />
                <div className="col-span-4">
                  <p>{product?.name}</p>
                  {!product?.is_default && (
                    <div className="flex items-center gap-4 mt-2">
                      {variations?.map((variant) => {
                        const {
                          variation: { type_value, name },
                        } = variant;
                        if (type_value?.startsWith("#")) {
                          return (
                            <div
                              className="w-14 h-8 rounded-md"
                              style={{ backgroundColor: type_value }}
                              key={variant?.id}
                            ></div>
                          );
                        } else {
                          return (
                            <div
                              key={variant.id}
                              className="bg-gray-100 py-1 px-2 rounded-md"
                            >
                              {name}
                            </div>
                          );
                        }
                      })}
                    </div>
                  )}
                </div>
                <p>{quantity}</p>
                <p>{priceFormat(price)} MMK</p>
                <p>{priceFormat(product_promo ? product_promo : 0)} MMK</p>
                <p>{priceFormat(summary_price)} MMK</p>
              </div>
              {get_y_products && get_y_products?.length > 0 && (
                <div className="border-b pb-2 px-4">
                  <h5 className="text-red-500">Gifts</h5>
                  <div className="flex flex-wrap gap-6">
                    {get_y_products.map((prod) => (
                      <div key={prod.id} className="flex items-center gap-4">
                        <img
                          src={prod?.image}
                          alt=""
                          className="w-10 aspect-square"
                        />
                        <div className="col-span-4">
                          <p>{prod?.name}</p>

                          <div className="flex items-center gap-4 mt-2">
                            {prod.variations?.map((v) => {
                              if (v.type_value?.startsWith("#")) {
                                return (
                                  <div
                                    className="w-10 h-6 rounded"
                                    style={{ backgroundColor: v.type_value }}
                                    key={v?.id}
                                  ></div>
                                );
                              } else {
                                return (
                                  <div
                                    key={v.id}
                                    className="bg-gray-100 grid place-items-center px-2 text-xs h-6 rounded-md"
                                  >
                                    {v.name}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          );
        })}
        <div className="grid grid-cols-8 p-4 items-center border-b">
          {[1, 2, 3, 4, 5, 6].map((d) => (
            <p key={d}></p>
          ))}
          <p className="text-right pr-4 font-bold col-span-7 mr-12">
            Total Price
          </p>
          <p>{priceFormat(total)} MMK</p>
        </div>
      </div>
    </div>
  );
}
