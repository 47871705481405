import { MdClose, MdPendingActions } from "react-icons/md";
import { FaBox, FaTruck, FaFlag, FaCheckCircle } from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";
import { GiCardPickup } from "react-icons/gi";

import React from "react";

const statusIcons = {
  cancelled: <MdClose className="text-xl" />,
  pending: <MdPendingActions className="text-2xl" />,
  picking: <GiCardPickup className="text-2xl" />,
  qc: <FaCheckCircle className="text-xl" />,
  packing: <FaBox className="text-lg" />,
  delivery: <RiAdminFill className="text-xl" />,
  delivering: <FaTruck className="text-xl" />,
  delivered: <FaFlag className="text-xl" />,
  failed: <FaFlag className="text-xl" />,
};

export default function OrderProgress({ assigns = [], cancelled }) {
  let statues = assigns?.map((assign) => assign.status);

  return (
    <div className="mt-10 mb-20 flex items-center justify-end">
      <Stage
        status={cancelled ? "cancelled" : "pending"}
        active={true}
        hideTail={true}
        failed={cancelled}
      />
      <Stage status="picking" active={statues.includes("picking")} />
      <Stage status="qc" active={statues.includes("qc")} />
      <Stage status="packing" active={statues.includes("packing")} />
      <Stage status="delivery" active={statues.includes("delivery")} />
      <Stage status="delivering" active={statues.includes("delivering")} />
      <Stage
        status={statues.includes("failed") ? "failed" : "delivered"}
        active={statues.includes("failed") || statues.includes("delivered")}
        failed={statues.includes("failed")}
        max={true}
      />
    </div>
  );
}

function Stage({ status, active, failed = false, hideTail = false }) {
  return (
    <div className={`relative w-full flex justify-center`}>
      {!hideTail && (
        <div
          className={`h-1 w-full absolute right-full top-1/2 -translate-y-1/2 left-0 -translate-x-1/2 ${
            active && !failed
              ? "bg-logo_color"
              : failed && active
              ? "bg-red-500"
              : "bg-gray-300"
          }`}
        ></div>
      )}

      <div
        className={`w-12 aspect-square text-white rounded-full grid place-items-center relative z-10 ${
          active && !failed
            ? "bg-logo_color"
            : !active && !failed
            ? "bg-gray-300"
            : "bg-red-500"
        }`}
      >
        {statusIcons[status]}
      </div>
      <p
        className={`uppercase absolute text-white text-xs px-2 py-1 rounded-2xl -bottom-8 ${
          active && !failed
            ? "bg-logo_color"
            : failed && active
            ? "bg-red-500"
            : "bg-gray-300"
        }`}
      >
        {status}
      </p>
    </div>
  );
}
