import React from "react";

export default function AddressDetail({
  name,
  email,
  phone,
  location,
  address,
  drop_ship = null,
  type,
  city,
}) {
  return (
    <div>
      <h3 className="font-bold text-lg mb-4 w-full">{type} Details</h3>
      <ul className="flex flex-col gap-2">
        <li className="flex items-center">
          <div className="font-bold w-32">Name</div>:
          <span className="ml-4">{name}</span>
        </li>
        <li className="flex items-center">
          <div className="font-bold w-32">Phone</div>:
          <span className="ml-4">{phone}</span>
        </li>
        <li className="flex items-center">
          <div className="font-bold w-32">Email</div>:
          <span className="ml-4">{email}</span>
        </li>
        {drop_ship ? (
          <li className="flex items-start">
            <div className="font-bold w-32">Address</div>:
            <span className="ml-4">
              {drop_ship.township?.name}, {drop_ship.city?.name}
            </span>
          </li>
        ) : (
          <li className="flex items-start">
            <div className="font-bold w-32">Address</div>:
            <span className="ml-4">
              {city?.name}, {location?.name}, {address}
            </span>
          </li>
        )}
        {drop_ship && (
          <li className="flex items-center">
            <div className="font-bold w-32">Car Gate</div>:
            <span className="ml-4">{drop_ship?.bus_gate}</span>
          </li>
        )}
      </ul>
    </div>
  );
}
