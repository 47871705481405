import React from "react";
import { Status } from "../../pages/Orders/OrderList";
import { dateFormat, priceFormat } from "../../_helper/helper";

export default function InvoiceDetail({ deli_fee, total, status, date }) {
  return (
    <div>
      <h3 className="font-bold text-lg mb-4 mt-8">Invoice Details</h3>
      <ul className="flex flex-col gap-2">
        <li className="flex items-center">
          <div className="font-bold w-32">Delivery Fee</div>:
          <span className="ml-4">{deli_fee}</span>
        </li>
        <li className="flex items-center">
          <div className="font-bold w-32">Total Price</div>:
          <span className="ml-4">{priceFormat(total)} MMK</span>
        </li>
        <li className="flex items-center">
          <div className="font-bold w-32">Status</div>:
          <span className="ml-4">
            <Status item={{ status }} />
          </span>
        </li>
        <li className="flex items-center">
          <div className="font-bold w-32">Date</div>:
          <span className="ml-4">{dateFormat(date, "date")}</span>
        </li>
      </ul>
    </div>
  );
}
