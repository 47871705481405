import React from "react";
import { useState } from "react";
import { fetchDataFrom, postDataTo } from "../../_helper/fetcher";

import {
  FaChevronLeft,
  FaChevronRight,
  FaFileUpload,
  FaSearch,
} from "react-icons/fa";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useEffect } from "react";
import useButtons from "../../hooks/useButtons";

function debounce(func) {
  let timeoutId;

  return function (...args) {
    const context = this;

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, 300);
  };
}
function Upload({ file, title, setTitle, id }) {
  return (
    <div className="flex items-center justify-center">
      <>
        <figure className="bg-gray-50 w-16 aspect-square object-contain">
          <img
            src={URL.createObjectURL(file)}
            className="w-full h-full object-contain"
            alt=""
          />
        </figure>
        <input
          required
          name="title"
          className="name-input ml-4 2-full"
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => debounce(setTitle(e.target.value, id))}
        />
      </>
    </div>
  );
}

export const ManualUpload = React.memo(Upload);

const SelectMedia = ({ control, close, isMulti = false }) => {
  const [selected, setSelected] = useState([]);
  const [manualUpload, setManualUpload] = useState(null);
  const [titles, setTitles] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [pages, setPages] = useState();
  const [search, setSearch] = useState("");
  const [debounced, setDebounced] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    let timeoutId;

    if (search) {
      timeoutId = setTimeout(() => {
        setDebounced(search);
      }, 500);
    }

    return () => clearTimeout(timeoutId);
  }, [search]);

  const getMedia = async (query) => {
    try {
      const data = await fetchDataFrom("media?limit=18&page=" + page + query);
      setPages(data?.meta?.pages);
      return data?.data;
    } catch (error) {
      toast.error(error?.message || "Something went wrong with fetching media");
    }
  };

  const { SaveButton, CloseButton } = useButtons();

  const {
    data: medias,
    isLoading,
    isError,
    refetch,
  } = useQuery(["medias", page, debounced], () =>
    getMedia("&search=" + debounced)
  );

  function handleSelecting(media) {
    if (isMulti) {
      const isThere = selected.find((file) => file.id === media.id);
      if (!isThere) setSelected([...selected, media]);
      else setSelected(selected.filter((file) => file.id !== media.id));
    } else {
      setSelected([media]);
    }
  }

  async function handleManualUpload(e) {
    e.preventDefault();
    const formData = new FormData();
    manualUpload?.forEach((image, index) => {
      formData.append(`images[${index}][title]`, titles[index]);
      formData.append(`images[${index}][file]`, image);
    });
    const res = await postDataTo("media-upload", formData);
    if (res) {
      setManualUpload(null);
      refetch();
    }
  }
  const { mutate, isLoading: isUploading } = useMutation({
    mutationFn: handleManualUpload,
    onError: (err) => toast.error(err.message),
  });

  function handleTitle(value, id) {
    let titlesCP = [...titles];
    titlesCP[id] = value;
    setTitles(titlesCP);
  }
  return (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-20">
      <div className="relative w-full my-6 mx-auto max-w-3xl ">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <label className="form-label w-full">Select Media</label> (or){" "}
              <label
                className="flex items-center gap-1 cursor-pointer underline underline-offset-2 form-label"
                htmlFor="manual"
              >
                <FaFileUpload /> Upload
              </label>
              <input
                onChange={(e) => setManualUpload([...e.target.files])}
                type="file"
                max={10}
                className="hidden"
                multiple
                accept="image/*"
                id="manual"
              />
            </div>
            <div className="flex items-center w-max gap-2">
              <button
                type="button"
                className="text-red-400"
                onClick={() => {
                  setDebounced("");
                  setSearch("");
                  getMedia("&page=1");
                }}
              >
                reset
              </button>
              <div className="w-[17rem] relative ml-auto">
                <input
                  type="text"
                  className="form-input pr-6"
                  placeholder="Search media"
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button
                  type="button"
                  onClick={() => getMedia("&search=" + search)}
                  className="absolute right-2 top-1/2 -translate-y-1/2"
                >
                  <FaSearch />
                </button>
              </div>
            </div>
          </div>

          {manualUpload ? (
            <>
              <div className="grid grid-cols-2 gap-4 mt-6">
                {manualUpload?.slice(0, 10)?.map((file, i) => (
                  <ManualUpload
                    key={i}
                    file={file}
                    control={setManualUpload}
                    id={i}
                    title={titles[i]}
                    setTitle={handleTitle}
                  />
                ))}
              </div>
              <div className="flex items-center mt-2 justify-end">
                <CloseButton
                  text="Cancel"
                  onClick={() => setManualUpload(null)}
                />
                <SaveButton
                  text="Upload"
                  isLoading={isUploading}
                  loadingText="Uploading..."
                  onClick={mutate}
                />
              </div>{" "}
            </>
          ) : (
            <>
              <div className="grid grid-cols-6 text-center min-h-[27rem] grid-rows-3 h-full py-4 gap-4">
                {isError ? (
                  <p className="absolute w-full top-1/2 -translate-y-1/2 text-red-200">
                    Unexpected error
                  </p>
                ) : isLoading ? (
                  <p className="absolute w-full top-1/2 -translate-y-1/2">
                    Loading...
                  </p>
                ) : !isLoading && medias?.length > 0 ? (
                  <>
                    {medias.map((media) => (
                      <button
                        type="button"
                        className={`${
                          selected.filter((file) => file.id === media.id)
                            .length > 0 && "border-2 border-logo_color"
                        } bg-gray-100 p-1`}
                        onClick={() => handleSelecting(media)}
                        key={media.id}
                        title={media.title}
                      >
                        <img
                          src={media.thumbnail || media.file}
                          alt={media.title}
                          className="w-full aspect-square object-contain"
                        />
                        <p className="truncate overflow-hidden whitespace-nowrap text-sm max-h-5rem">
                          {media.title}
                        </p>
                      </button>
                    ))}
                  </>
                ) : (
                  <div className="absolute w-full top-1/2 -translate-y-1/2">
                    No media to show
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between">
                <div className="flex text-2xl gap-2 items-center mt-4">
                  <button
                    type="button"
                    disabled={page === 1}
                    className="disabled:text-gray-400"
                    onClick={() => setPage(page - 1)}
                  >
                    <FaChevronLeft />
                  </button>
                  <input
                    type="number"
                    onChange={(e) => setPage(e.target.value)}
                    className="w-12 border-none text-center appearance-none"
                    min={1}
                    value={page}
                  />
                  <button
                    disabled={page === pages}
                    className="disabled:text-gray-400 -ml-4"
                    type="button"
                    onClick={() => setPage(page + 1)}
                  >
                    <FaChevronRight />
                  </button>
                </div>
                <div className="flex text-2xl gap-2 items-center mt-4">
                  <button
                    className="btn-sm btn-danger"
                    type="button"
                    onClick={() => {
                      selected?.length > 0 ? setSelected([]) : close();
                    }}
                  >
                    {selected?.length > 0 ? "Clear" : "Close"}
                  </button>
                  {selected?.length > 0 && (
                    <button
                      className="btn-sm btn-olive"
                      type="button"
                      onClick={() => {
                        control(selected);
                        setSelected([]);
                        close();
                      }}
                    >
                      Finish
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectMedia;
