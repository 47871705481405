import React from "react";
import { useSearchParams } from "react-router-dom";
import useQueryString from "../../hooks/useQueryString";
import { useState } from "react";
import { useEffect } from "react";

export default function ClearFilterButton({ param_key, reset }) {
  const [show, setShow] = useState(false);
  const [searchParams] = useSearchParams();
  const { removeParam } = useQueryString();
  const param = searchParams.get(param_key);
  useEffect(() => {
    setShow(param ? true : false);
  }, [param]);
  if (show) {
    return (
      <button
        type="button"
        className="bg-red-400 hover:bg-red-500 rounded-2xl py-[2px] px-2 absolute -right-2 -top-2 text-white text-xs"
        onClick={() => {
          reset();
          removeParam(param_key);
        }}
      >
        Clear
      </button>
    );
  } else return "";
}
