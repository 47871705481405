import React, { useState } from "react";
import DeletePopUp from "../components/popups/DeletePopUp";
import Pagination from "../components/shared/Pagination";
import useQueryString from "../hooks/useQueryString";
import { deleteDataFrom, fetchDataFrom } from "../_helper/fetcher";
import { useQuery } from "react-query";
import TableList from "../components/shared/TableList";
import useRefetch from "../hooks/useRefetch";
import FormModalContainer from "../components/shared/FormModalContainer";
import useButtons from "../hooks/useButtons";

const columnsHead = [
  { name: "name", accessor: ["name"] },
  { name: "email", accessor: ["email"] },
  { name: "subject", accessor: ["subject"] },
];

export default function Contacts() {
  const [pagin, setPagin] = useState({});

  const [showDeleteModal, setShowDeleteModal] = useState({
    id: "",
    show: false,
  });
  const [showDetailModal, setShowDetailModal] = useState({
    show: false,
    data: {},
  });

  const { queryString } = useQueryString();
  const { refetchList } = useRefetch();

  async function getData() {
    const res = await fetchDataFrom("contact-us" + queryString);
    if (res.error) return null;
    const items = res.data;
    const { per_page, pages, total } = res.meta;
    setPagin({
      pages: pages,
      perPage: per_page,
      total: total,
    });
    return items;
  }

  async function deleteBrand() {
    await deleteDataFrom("contact-us/" + showDeleteModal.id);
    setShowDeleteModal({ id: "", show: false });
    refetchList("contacts");
  }

  const { CloseButton } = useButtons();

  const {
    data: contacts,
    isLoading,
    isError,
    error,
  } = useQuery(["contacts", queryString], getData);
  return (
    <>
      <div className="px-2">
        <div className="box">
          <div className="box-content p-0">
            <div className="box-tools">
              <div className="box-title">
                <h3>Contacts</h3>
              </div>
            </div>
            {showDetailModal.show && (
              <FormModalContainer title={"Message"}>
                <div>{showDetailModal?.data?.message}</div>
                <div className="flex justify-end">
                  <CloseButton
                    onClick={() =>
                      setShowDetailModal({ show: false, data: {} })
                    }
                  />
                </div>
              </FormModalContainer>
            )}
            <TableList
              onDelete={(item) => {
                setShowDeleteModal({
                  id: item.id,
                  show: true,
                });
              }}
              onDetail={(item) =>
                setShowDetailModal({ show: true, data: item })
              }
              customColumns={[{ name: "message" }]}
              customElements={[<Message />]}
              data={contacts}
              delPer="delete_contact"
              columns={columnsHead}
              isLoading={isLoading}
              isError={isError}
              error={error}
            />
          </div>
        </div>
        {showDeleteModal.show && (
          <DeletePopUp
            close={() => setShowDeleteModal({ id: "", show: false })}
            func={deleteBrand}
          />
        )}
      </div>
      {pagin?.perPage <= pagin?.total && <Pagination pagin={pagin} />}
    </>
  );
}

function Message({ item }) {
  return (
    <p className="w-[10rem] text-ellipsis overflow-hidden">{item?.message}</p>
  );
}
