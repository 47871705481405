import { lazy } from "react";
import ProtectedRoute from "../components/shared/ProtectedRoute";
import { Route, Routes } from "react-router-dom";
import OrderDetail from "../pages/Orders/OrderDetail";
import Exports from "../pages/Orders/Exports";

const OrdersList = lazy(() => import("../pages/Orders/OrderList"));

export default function OrderRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute permission="view_order">
            <OrdersList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:id"
        element={
          <ProtectedRoute permission="view_order">
            <OrderDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/exports"
        element={
          <ProtectedRoute permission="view_order">
            <Exports />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
