import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sku_ids: [],
  sku: [],
  product_ids: [],
  all_sku: [],
};

export const promotion = createSlice({
  name: "promo",
  initialState,
  reducers: {
    setSKUs: (state, action) => {
      const { sku_ids, sku } = action.payload;
      state.sku_ids = [...state?.sku_ids, ...sku_ids];
      state.sku = sku;
    },
    removeProduct: (state, action) => {
      const { product_id } = action.payload;
      state.product_ids = state?.product_ids?.filter((p) => p !== product_id);
    },
    addProduct: (state, action) => {
      const { product_id } = action.payload;
      state.product_ids = Array.from(
        new Set([...state?.product_ids, product_id])
      );
    },
    clearProduct: (state) => {
      state.product_ids = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSKUs, addProduct, removeProduct, clearProduct } =
  promotion.actions;

export default promotion.reducer;
