import React from "react";

export default function CouponServicesPromo({ items = [] }) {
  return (
    <div className="mt-8">
      <h3 className="font-bold text-lg mb-4">
        Free Services Get By Using Coupon
      </h3>
      <div className="grid grid-cols-3 gap-4">
        {items.map((item) => {
          return (
            <div
              key={item.id}
              className="flex items-center gap-4 bg-gray-100 p-4"
            >
              <p>{item?.service?.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
