import React from "react";

const LogoutPopup = ({ setShowModal, func }) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-sm">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center justify-center p-8 rounded-t">
              <h6 className="text-lg text-center font-semibold text-black">
                Do you really want to Log out?
              </h6>
            </div>
            <div className="relative p-6 flex items-center justify-center gap-12">
              <button
                className="text-red-500"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
              <button className="btn-md btn-olive" type="submit" onClick={func}>
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default LogoutPopup;
